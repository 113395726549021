$(function () {
  var $date = $('.js-stock-date'),
      $price = $('.js-stock-price'),
      $change = $('.js-stock-change'),
      $high = $('.js-stock-high'),
      $low = $('.js-stock-low'),
      $symbol = $('.js-stock-symbol');
  // if price element is present on the page in breadcrumbs or investors block, run stock price update interval
  if ($price.length) {
    setInterval(() => {
      $.get('/api/StockPrice/getStockPrice', function (data) {
        $date.text(data.Date);
        $price.text(data.Bid);
        $change.text(data.Change);
        $high.text(data.High);
        $low.text(data.Low);
        $symbol.text(data.Symbol);
      });
    }, 60000);
  }
});